

let tiresAll = [
    {
        id: 10001,
        model: "Bridgestone Noranza ",
        size: "195/70/15C",
        season: "nasta",
        year: "2012",
        price: "70€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10001/mic3.jpg"),
            image2: require("../components/images/10001/mic2.jpg"),
            image3: require("../components/images/10001/mic1.jpg")
        },
        quantity: 2,
        hidden: false
    },
    {
        id: 10002,
        model: "Nokian Hakkapeliitta R3",
        size: "225/50/18",
        season: "kitka",
        year: "2018",
        price: "70€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10002/mic1.jpg"),
            image2: require("../components/images/10002/mic2.jpg"),
            image3: require("../components/images/10002/mic3.jpg")
        },
        quantity: 2,
        hidden: false
    },
    {
        id: 10003,
        model: "Nokian Hakkapeliitta R3",
        size: "235/45/18",
        season: "kitka",
        year: "2019",
        price: "80€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10003/mic1.jpg"),
            image2: require("../components/images/10003/mic2.jpg"),
            image3: require("../components/images/10003/mic3.jpg")
        },
        quantity: 2,
        hidden: false
    },
    {
    id: 10004,
    model: "Continental IceContact 7",
    size: "235/45/18",
    season: "kitka",
    year: "2020",
    price: "80€",
    pinta: "6mm",
    images: {
        image1: require("../components/images/10004/mic1.jpg"),
        image2: require("../components/images/10004/mic2.jpg"),
        image3: require("../components/images/10004/mic3.jpg")
    },
    quantity: 2,
    hidden: true
    },
    {
        id: 10005,
        model: "",
        size: "235/45/18",
        season: "kitka",
        year: "2020",
        price: "80€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10005/mic1.jpg"),
            image2: require("../components/images/10005/mic2.jpg"),
            image3: require("../components/images/10005/mic3.jpg")
        },
        quantity: 2,
        hidden: false
    },
    {
            id: 10006,
            model: "Nokian Hakkapeliitta R3 Run flat",
            size: "225/50/17",
            season: "kitka",
            year: "2018",
            price: "170€",
            pinta: "7,5mm",
            images: {
                image1: require("../components/images/10006/mic1.jpg"),
                image2: require("../components/images/10006/mic2.jpg"),
                image3: require("../components/images/10006/mic3.jpg")
            },
            quantity: 4,
            hidden: true
    },
    {
                id: 10007,
                model: "",
                size: "235/35/19",
                season: "nasta",
                year: "2019",
                price: "170€",
                pinta: "8,5mm",
                images: {
                    image1: require("../components/images/10007/mic1.jpg"),
                    image2: require("../components/images/10007/mic2.jpg"),
                    image3: require("../components/images/10007/mic3.jpg")
                },
                quantity: 4,
                hidden: false
    },
    {
                    id: 10008,
                    model: "Continental",
                    size: "245/45/19",
                    season: "kitka",
                    year: "2016",
                    price: "70€",
                    pinta: "6,5mm",
                    images: {
                        image1: require("../components/images/10008/mic1.jpg"),
                        image2: require("../components/images/10008/mic2.jpg"),
                        image3: require("../components/images/10008/mic3.jpg")
                    },
                    quantity: 2,
                    hidden: false
    },
    {
                    id: 10009,
                    model: "Continental",
                    size: "245/35/19",
                    season: "kitka",
                    year: "2021",
                    price: "150€",
                    pinta: "5,5mm",
                    images: {
                        image1: require("../components/images/10009/mic1.jpg"),
                        image2: require("../components/images/10009/mic2.jpg"),
                        image3: require("../components/images/10009/mic3.jpg")
                    },
                    quantity: 4,
                    hidden: false
    },
    {
        id: 10010,
        model: "Nokian Hakkapeliitta CR3",
        size: "205/70/15C",
        season: "nasta",
        year: "2017",
        price: "100€",
        pinta: "8,5mm",
        images: {
            image1: require("../components/images/10010/mic1.jpg"),
            image2: require("../components/images/10010/mic2.jpg"),
            image3: require("../components/images/10010/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10011,
        model: "Continental",
        size: "235/40/19",
        season: "kitka",
        year: "2019",
        price: "180€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10011/mic1.jpg"),
            image2: require("../components/images/10011/mic2.jpg"),
            image3: require("../components/images/10011/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10012,
        model: "Viatti",
        size: "255/45/18",
        season: "nasta",
        year: "2020",
        price: "160€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10012/mic1.jpg"),
            image2: require("../components/images/10012/mic2.jpg"),
            image3: require("../components/images/10012/mic3.jpg"),
            image4: require("../components/images/10012/mic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10013,
        model: "Nokian Hakkapeliitta R3",
        size: "225/60/18",
        season: "kitka",
        year: "2016",
        price: "170€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10013/mic1.jpg"),
            image2: require("../components/images/10013/mic2.jpg"),
            image3: require("../components/images/10013/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10014,
        model: "Michelin X Green",
        size: "245/45/18",
        season: "kitka",
        year: "",
        price: "170€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10014/mic1.jpg"),
            image2: require("../components/images/10014/mic2.jpg")
            },
        quantity: 4,
        hidden: true
    },
    {
        id: 10015,
        model: "Continental Viking Contact 7",
        size: "245/45/18",
        season: "kitka",
        year: "2018",
        price: "120€",
        pinta: "6,2mm",
        images: {
            image1: require("../components/images/10015/mic1.jpg"),
            image2: require("../components/images/10015/mic2.jpg"),
            image3: require("../components/images/10015/mic3.jpg")
            },
        quantity: 4,
        hidden: true
    },
    {
        id: 10016,
        model: "Nokian Hakkapeliitta 8",
        size: "205/60/16",
        season: "nasta",
        year: "2015",
        price: "80€",
        pinta: "8,5mm",
        images: {
            image1: require("../components/images/10016/mic1.jpg"),
            image2: require("../components/images/10016/mic2.jpg"),
            image3: require("../components/images/10016/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10017,
        model: "Nokian Hakkapeliitta C3",
        size: "195/70/15C",
        season: "nasta",
        year: "2016",
        price: "80€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10017/mic1.jpg"),
            image2: require("../components/images/10017/mic2.jpg"),
            image3: require("../components/images/10017/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10018,
        model: "Continental",
        size: "195/65/15",
        season: "nasta",
        year: "2013",
        price: "120€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10018/mic1.jpg"),
            image2: require("../components/images/10018/mic2.jpg"),
            image3: require("../components/images/10018/mic3.jpg")
            },
        quantity: 4,
        hidden: true
    },
    {
        id: 10019,
        model: "Nokian Hakkapeliitta 7",
        size: "175/65/15",
        season: "nasta",
        year: "2013",
        price: "50€",
        pinta: "7,3mm",
        images: {
            image1: require("../components/images/10019/mic1.jpg"),
            image2: require("../components/images/10019/mic2.jpg"),
            image3: require("../components/images/10019/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10020,
        model: "Bridgestone Nordaza",
        size: "205/65/16C",
        season: "nasta",
        year: "2019",
        price: "180€",
        pinta: "8,5mm",
        images: {
            image1: require("../components/images/10020/mic1.jpg"),
            image2: require("../components/images/10020/mic2.jpg"),
            image3: require("../components/images/10020/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10021,
        model: "Nokian Hakkapeliitta R3",
        size: "205/55/16",
        season: "kitka",
        year: "2021",
        price: "70€",
        pinta: "7,3mm",
        images: {
            image1: require("../components/images/10021/mic1.jpg"),
            image2: require("../components/images/10021/mic2.jpg"),
            image3: require("../components/images/10021/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10022,
        model: "Continental 7",
        size: "225/55/18",
        season: "kitka",
        year: "2021",
        price: "170€",
        pinta: "7,3mm",
        images: {
            image1: require("../components/images/10022/mic1.jpg"),
            image2: require("../components/images/10022/mic2.jpg"),
            image3: require("../components/images/10022/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10023,
        model: "Michelin x-ice 4",
        size: "235/45/18",
        season: "nasta",
        year: "2021",
        price: "90€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10023/mic1.jpg"),
            image2: require("../components/images/10023/mic2.jpg"),
            image3: require("../components/images/10023/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10024,
        model: "Continental ice contact 2",
        size: "185/60/15",
        season: "nasta",
        year: "2018",
        price: "120€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10024/mic1.jpg"),
            image2: require("../components/images/10024/mic2.jpg"),
            image3: require("../components/images/10024/mic3.jpg"),
            image4: require("../components/images/10024/mic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10025,
        model: "Michelin x-ice 4",
        size: "205/55/16",
        season: "nasta",
        year: "2020",
        price: "70€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10025/mic1.jpg"),
            image2: require("../components/images/10025/mic2.jpg"),
            image3: require("../components/images/10025/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10026,
        model: "Pirelli Ice Zero",
        size: "225/50/17",
        season: "nasta",
        year: "2019",
        loadSpdIdx: "98T",
        price: "80€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10026/mic1.jpg"),
            image2: require("../components/images/10026/mic2.jpg"),
            image3: require("../components/images/10026/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10027,
        model: "Nokian Hakkapeliitta 8",
        size: "205/55/17",
        season: "nasta",
        year: "2017",
        price: "100€",
        pinta: "9mm",
        images: {
            image1: require("../components/images/10027/mic1.jpg"),
            image2: require("../components/images/10027/mic2.jpg"),
            image3: require("../components/images/10027/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10028,
        model: "Continental 2",
        size: "215/60/16",
        season: "nasta",
        year: "2016",
        price: "80€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10028/mic1.jpg"),
            image2: require("../components/images/10028/mic2.jpg"),
            image3: require("../components/images/10028/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10029,
        model: "Continental 7",
        size: "205/55/16",
        season: "kitka",
        year: "2018",
        price: "70€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10029/mic1.jpg"),
            image2: require("../components/images/10029/mic2.jpg"),
            image3: require("../components/images/10029/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10030,
        model: "Michelin x ice 4",
        size: "245/45/18",
        season: "nasta",
        year: "2020",
        price: "80€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10030/mic1.jpg"),
            image2: require("../components/images/10030/mic2.jpg"),
            image3: require("../components/images/10030/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10031,
        model: "Michelin X-Ice Snow",
        size: "225/45/17",
        season: "kitka",
        year: "2020",
        loadSpdIdx: "94H",
        price: "80€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10031/mic1.jpg"),
            image2: require("../components/images/10031/mic2.jpg"),
            image3: require("../components/images/10031/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10032,
        model: "Nokian Hakkapeliitta C",
        size: "215/65/16",
        season: "nasta",
        year: "2022",
        price: "100€",
        pinta: "9mm",
        images: {
            image1: require("../components/images/10032/mic1.jpg"),
            image2: require("../components/images/10032/mic2.jpg"),
            image3: require("../components/images/10032/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10033,
        model: "Continental",
        size: "225/45/17",
        season: "kitka",
        year: "2017",
        price: "70€",
        pinta: "5,5mm",
        images: {
            image1: require("../components/images/10033/mic1.jpg"),
            image2: require("../components/images/10033/mic2.jpg"),
            image3: require("../components/images/10033/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10034,
        model: "Michelin X-Ice North 4",
        size: "245/45/17",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "99T",
        price: "80€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10034/mic1.jpg"),
            image2: require("../components/images/10034/mic2.jpg"),
            image3: require("../components/images/10034/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10035,
        model: "Michelin 4",
        size: "245/45/18",
        season: "nasta",
        year: "2020",
        price: "80€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10035/mic1.jpg"),
            image2: require("../components/images/10035/mic2.jpg"),
            image3: require("../components/images/10035/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10036,
        model: "Nokian Hakkapeliitta C3",
        size: "215/60/16C",
        season: "nasta",
        year: "2020",
        price: "100€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10036/mic1.jpg"),
            image2: require("../components/images/10036/mic2.jpg"),
            image3: require("../components/images/10036/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10037,
        model: "Continental IceContact 2",
        size: "235/55/18",
        season: "nasta",
        year: "2019",
        loadSpdIdx: "104T",
        price: "200€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10037/mic1.jpg"),
            image2: require("../components/images/10037/mic2.jpg"),
            image3: require("../components/images/10037/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10038,
        model: "HankookWinter I*pike RS2",
        size: "225/45/17",
        season: "nasta",
        year: "2020",
        loadSpdIdx: "94",
        price: "100€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10038/mic1.jpg"),
            image2: require("../components/images/10038/mic2.jpg"),
            image3: require("../components/images/10038/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10039,
        model: "Nokian Hakkapeliitta R3",
        size: "225/50/17",
        season: "kitka",
        year: "2019",
        price: "170€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10039/mic1.jpg"),
            image2: require("../components/images/10039/mic2.jpg"),
            image3: require("../components/images/10039/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10040,
        model: "Pirrelli Ice Zero",
        size: "225/50/17",
        season: "nasta",
        year: "2014",
        loadSpdIdx: "98T",
        price: "120€",
        pinta: "7,7mm",
        images: {
            image1: require("../components/images/10040/mic1.jpg"),
            image2: require("../components/images/10040/mic2.jpg"),
            image3: require("../components/images/10040/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10041,
        model: "Continental IceContact 2",
        size: "205/55/16",
        season: "nasta",
        year: "2015",
        loadSpdIdx: "94T",
        price: "120€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10041/mic1.jpg"),
            image2: require("../components/images/10041/mic2.jpg"),
            image3: require("../components/images/10041/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10042,
        model: "Gislaved",
        size: "275/40/20",
        season: "nasta",
        year: "2019",
        price: "250€",
        pinta: "9mm",
        images: {
            image1: require("../components/images/10042/mic1.jpg"),
            image2: require("../components/images/10042/mic2.jpg"),
            image3: require("../components/images/10042/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10043,
        model: "",
        size: "225/45/18",
        season: "kitka",
        year: "2019",
        price: "70€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10043/mic1.jpg"),
            image2: require("../components/images/10043/mic2.jpg"),
            image3: require("../components/images/10043/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10044,
        model: "Michelin x4",
        size: "245/45/17",
        season: "nasta",
        year: "2018",
        price: "70€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10044/mic1.jpg"),
            image2: require("../components/images/10044/mic2.jpg"),
            image3: require("../components/images/10044/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10045,
        model: "Bridgestone Noranza 001",
        size: "215/50/17",
        season: "nasta",
        year: "2016",
        loadSpdIdx: "95T",
        price: "70€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10045/mic1.jpg"),
            image2: require("../components/images/10045/mic2.jpg"),
            image3: require("../components/images/10045/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10046,
        model: "Continental IceContact 2",
        size: "235/45/18",
        season: "nasta",
        year: "2017",
        loadSpdIdx: "98T",
        price: "180€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10046/mic1.jpg"),
            image2: require("../components/images/10046/mic2.jpg"),
            image3: require("../components/images/10046/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10047,
        model: "Continental 7",
        size: "225/45/17",
        season: "kitka",
        year: "2018",
        price: "70€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10047/mic1.jpg"),
            image2: require("../components/images/10047/mic2.jpg"),
            image3: require("../components/images/10047/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10048,
        model: "Continental 2",
        size: "225/55/18",
        season: "nasta",
        year: "2019",
        price: "200€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10048/mic1.jpg"),
            image2: require("../components/images/10048/mic2.jpg"),
            image3: require("../components/images/10048/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10049,
        model: "Continental 2",
        size: "205/55/16",
        season: "nasta",
        year: "2015",
        price: "140€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10049/mic1.jpg"),
            image2: require("../components/images/10049/mic2.jpg"),
            image3: require("../components/images/10049/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10050,
        model: "MarshalWinterCraft Ice Wi31",
        size: "225/45/17",
        season: "nasta",
        year: "2016",
        loadSpdIdx: "94T",
        price: "180€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10050/mic1.jpg"),
            image2: require("../components/images/10050/mic2.jpg"),
            image3: require("../components/images/10050/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10051,
        model: "Michelin x4",
        size: "205/55/16",
        season: "nasta",
        year: "2021",
        price: "70€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10051/mic1.jpg"),
            image2: require("../components/images/10051/mic2.jpg"),
            image3: require("../components/images/10051/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10052,
        model: "Continental IceContact 2",
        size: "205/60/16",
        season: "nasta",
        year: "2017",
        loadSpdIdx: "96T",
        price: "70€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10052/mic1.jpg"),
            image2: require("../components/images/10052/mic2.jpg"),
            image3: require("../components/images/10052/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10053,
        model: "Continental 7",
        size: "225/55/17",
        season: "kitka",
        year: "2020",
        price: "160€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10053/mic1.jpg"),
            image2: require("../components/images/10053/mic2.jpg"),
            image3: require("../components/images/10053/mic3.jpg")
            },
        quantity: 4,
        hidden: true
    },
    {
        id: 10054,
        model: "Michelin x4",
        size: "195/65/15",
        season: "nasta",
        year: "2018",
        price: "130€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10054/mic1.jpg"),
            image2: require("../components/images/10054/mic2.jpg"),
            image3: require("../components/images/10054/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10102,
        model: "Michelin Primacy 4",
        size: "215/55/17",
        season: "kesä",
        year: "2019",
        price: "50€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10102/pic1.jpg"),
            image2: require("../components/images/10102/pic2.jpg"),
            image3: require("../components/images/10102/pic3.jpg"),
            image4: require("../components/images/10102/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10103,
        model: "Continental PremiumContact 6",
        size: "225/40/18",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "92Y",
        price: "50€",
        pinta: "3mm",
        images: {
            image1: require("../components/images/10103/pic1.jpg"),
            image2: require("../components/images/10103/pic2.jpg"),
            image3: require("../components/images/10103/pic3.jpg"),
            image4: require("../components/images/10103/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10106,
        model: "Triangle Sportex",
        size: "225/35/19",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "88Y",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10106/pic1.jpg"),
            image2: require("../components/images/10106/pic2.jpg"),
            image3: require("../components/images/10106/pic3.jpg"),
            image4: require("../components/images/10106/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10107,
        model: "Triangle Sportex",
        size: "275/30/19",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "96Y",
        price: "80€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10107/pic1.jpg"),
            image2: require("../components/images/10107/pic2.jpg"),
            image3: require("../components/images/10107/pic3.jpg"),
            image4: require("../components/images/10107/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10108,
        model: "Landsail  Sentury Quirin 990",
        size: "225/45/19",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "96Y",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10108/pic1.jpg"),
            image2: require("../components/images/10108/pic2.jpg"),
            image3: require("../components/images/10108/pic3.jpg"),
            image4: require("../components/images/10108/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10109,
        model: "Michelin  Pilot Sport 4s",
        size: "255/35/19",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "96Y",
        price: "150€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10109/pic1.jpg"),
            image2: require("../components/images/10109/pic2.jpg"),
            image3: require("../components/images/10109/pic3.jpg"),
            image4: require("../components/images/10109/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10110,
        model: "Hankook Ventus Prime 3",
        size: "205/60/16",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "92H",
        price: "50€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10110/pic1.jpg"),
            image2: require("../components/images/10110/pic2.jpg"),
            image3: require("../components/images/10110/pic3.jpg"),
            image4: require("../components/images/10110/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10112,
        model: "Vredestein Ultrac Vorti",
        size: "275/35/20",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "102Y",
        price: "100€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10112/pic1.jpg"),
            image2: require("../components/images/10112/pic2.jpg"),
            image3: require("../components/images/10112/pic3.jpg"),
            image4: require("../components/images/10112/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10113,
        model: "Linglong Sportmaster",
        size: "315/35/20",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "110Y",
        price: "100€",
        pinta: "3,5mm",
        images: {
            image1: require("../components/images/10113/pic1.jpg"),
            image2: require("../components/images/10113/pic2.jpg"),
            image3: require("../components/images/10113/pic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10114,
        model: "Pirelli P Zero",
        size: "275/40/20",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "106W",
        price: "100€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10114/pic1.jpg"),
            image2: require("../components/images/10114/pic2.jpg"),
            image3: require("../components/images/10114/pic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10116,
        model: "Continental VikingContact 7",
        size: "215/55/17",
        season: "kitka",
        year: "2019",
        loadSpdIdx: "98T",
        price: "170€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10116/pic1.jpg"),
            image2: require("../components/images/10116/pic2.jpg"),
            image3: require("../components/images/10116/pic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10117,
        model: "Nexen Winguard WinSpike WH62",
        size: "195/65/15",
        season: "nasta",
        year: "2015",
        loadSpdIdx: "",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10117/pic1.jpg"),
            image2: require("../components/images/10117/pic2.jpg"),
            image3: require("../components/images/10117/pic3.jpg"),
            image4: require("../components/images/10117/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10118,
        model: "Azenis FK510",
        size: "235/40/19",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "96Y",
        price: "150€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10118/pic1.jpg"),
            image2: require("../components/images/10118/pic2.jpg"),
            image3: require("../components/images/10118/pic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10119,
        model: "Linglong Green-Max",
        size: "215/45/17",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "91W",
        price: "50€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10119/pic1.jpg"),
            image2: require("../components/images/10119/pic2.jpg"),
            image3: require("../components/images/10119/pic3.jpg"),
            image4: require("../components/images/10119/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10120,
        model: "Michelin  X-Ice North 4",
        size: "215/60/16",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "99T",
        price: "130€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10120/pic1.jpg"),
            image2: require("../components/images/10120/pic2.jpg"),
            image3: require("../components/images/10120/pic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10121,
        model: "Bridgestone Noranza",
        size: "215/65/16",
        season: "nasta",
        year: "2016",
        loadSpdIdx: "102T",
        price: "80€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10121/pic1.jpg"),
            image2: require("../components/images/10121/pic2.jpg"),
            image3: require("../components/images/10121/pic3.jpg"),
            image4: require("../components/images/10121/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10122,
        model: "Continental  contipremium contact 5",
        size: "205/55/16",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "91V",
        price: "80€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10122/pic1.jpg"),
            image2: require("../components/images/10122/pic2.jpg"),
            image3: require("../components/images/10122/pic3.jpg"),
            //image4: require("../components/images/10122/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10123,
        model: "Michelin  Primacy 3",
        size: "225/50/18",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "95V",
        price: "100€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10123/pic1.jpg"),
            image2: require("../components/images/10123/pic2.jpg"),
            image3: require("../components/images/10123/pic3.jpg"),
            //image4: require("../components/images/10123/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10124,
        model: "Nokian  Hakka black",
        size: "295/40/20",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "110Y",
        price: "80€",
        pinta: "3,5mm",
        images: {
            image1: require("../components/images/10124/pic1.jpg"),
            image2: require("../components/images/10124/pic2.jpg"),
            image3: require("../components/images/10124/pic3.jpg"),
            image4: require("../components/images/10124/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10125,
        model: "Nokian  Hakka green 2",
        size: "185/60/15",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "88H",
        price: "40€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10125/pic1.jpg"),
            image2: require("../components/images/10125/pic2.jpg"),
            image3: require("../components/images/10125/pic3.jpg"),
            //image4: require("../components/images/10125/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10126,
        model: "Michelin  Primacy 4",
        size: "215/55/17",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "94W",
        price: "40€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10126/pic1.jpg"),
            image2: require("../components/images/10126/pic2.jpg"),
            image3: require("../components/images/10126/pic3.jpg"),
            image4: require("../components/images/10126/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10127,
        model: "Continental  PremiumContact 6",
        size: "215/55/17",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "94V",
        price: "40€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10127/pic1.jpg"),
            image2: require("../components/images/10127/pic2.jpg"),
            image3: require("../components/images/10127/pic3.jpg"),
            //image4: require("../components/images/10127/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10128,
        model: "Continental  ContiEcoContact 5",
        size: "215/60/17",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "96H",
        price: "100€",
        pinta: "5,5mm",
        images: {
            image1: require("../components/images/10128/pic1.jpg"),
            image2: require("../components/images/10128/pic2.jpg"),
            image3: require("../components/images/10128/pic3.jpg"),
            //image4: require("../components/images/10128/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10129,
        model: "Fortuna  Zonda GH18",
        size: "225/60/16",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "102V",
        price: "50€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10129/pic1.jpg"),
            image2: require("../components/images/10129/pic2.jpg"),
            image3: require("../components/images/10129/pic3.jpg"),
            //image4: require("../components/images/10129/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10130,
        model: "Michelin  Primacy 4",
        size: "205/55/16",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "91V",
        price: "70€",
        pinta: "4,5mm",
        images: {
            image1: require("../components/images/10130/pic1.jpg"),
            image2: require("../components/images/10130/pic2.jpg"),
            image3: require("../components/images/10130/pic3.jpg"),
            image4: require("../components/images/10130/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10131,
        model: "Minerva Ecospeed 2 SUV",
        size: "215/55/18",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "",
        price: "80€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10131/pic1.jpg"),
            image2: require("../components/images/10131/pic2.jpg"),
            image3: require("../components/images/10131/pic3.jpg"),
            //image4: require("../components/images/10130/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10132,
        model: "Continental  IceContact 2",
        size: "235/55/18",
        season: "nasta",
        year: "2020",
        loadSpdIdx: "104T",
        price: "200€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10132/pic1.jpg"),
            image2: require("../components/images/10132/pic2.jpg"),
            image3: require("../components/images/10132/pic3.jpg"),
            image4: require("../components/images/10132/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10133,
        model: "Nokian Hakkapeliitta 9",
        size: "215/50/17",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "",
        price: "150€",
        pinta: "6-8mm",
        images: {
            image1: require("../components/images/10133/pic1.jpg"),
            image2: require("../components/images/10133/pic2.jpg"),
            image3: require("../components/images/10133/pic3.jpg"),
            image4: require("../components/images/10133/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10134,
        model: "Nokian Hakkapeliitta 9",
        size: "205/60/16",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "",
        price: "120€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10134/pic1.jpg"),
            image2: require("../components/images/10134/pic2.jpg"),
            image3: require("../components/images/10134/pic3.jpg"),
            image4: require("../components/images/10134/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10135,
        model: "Kumho Ecsta PS91",
        size: "255/35/19",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "96Y",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10135/pic1.jpg"),
            image2: require("../components/images/10135/pic2.jpg"),
            image3: require("../components/images/10135/pic3.jpg"),
            //image4: require("../components/images/10135/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10136,
        model: "Gislaved Ultra Speed 2",
        size: "225/45/17",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "91Y",
        price: "130€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10136/pic1.jpg"),
            image2: require("../components/images/10136/pic2.jpg"),
            image3: require("../components/images/10136/pic3.jpg"),
            image4: require("../components/images/10136/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10137,
        model: "Linglong  Green-max HP 010",
        size: "185/60/15",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "88H",
        price: "40€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10137/pic1.jpg"),
            image2: require("../components/images/10137/pic2.jpg"),
            image3: require("../components/images/10137/pic3.jpg"),
            image4: require("../components/images/10137/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10138,
        model: "Continental  EcoContact 6",
        size: "195/60/18",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "96H",
        price: "50€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10138/pic1.jpg"),
            image2: require("../components/images/10138/pic2.jpg"),
            image3: require("../components/images/10138/pic3.jpg"),
            image4: require("../components/images/10138/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10139,
        model: "Continental  PremiumContact 7",
        size: "225/55/18",
        season: "kesä",
        year: "2023",
        loadSpdIdx: "96H",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10139/pic1.jpg"),
            image2: require("../components/images/10139/pic2.jpg"),
            image3: require("../components/images/10139/pic3.jpg"),
            image4: require("../components/images/10139/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10140,
        model: "Continental  EcoContact 6",
        size: "245/40/18",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "97Y",
        price: "80€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10140/pic1.jpg"),
            image2: require("../components/images/10140/pic2.jpg"),
            image3: require("../components/images/10140/pic3.jpg"),
            image4: require("../components/images/10140/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10141,
        model: "Antares Comfort A5",
        size: "255/35/20",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "97W",
        price: "100€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10141/pic1.jpg"),
            image2: require("../components/images/10141/pic2.jpg"),
            image3: require("../components/images/10141/pic3.jpg"),
            image4: require("../components/images/10141/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10142,
        model: "Kleber Transpro",
        size: "225/65/16C",
        season: "kesä",
        year: "2016",
        loadSpdIdx: "",
        price: "70€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10142/pic1.jpg"),
            image2: require("../components/images/10142/pic2.jpg"),
            image3: require("../components/images/10142/pic3.jpg"),
            image4: require("../components/images/10142/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10143,
        model: "Michelin  CrossClimate",
        size: "225/45/17",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "",
        price: "50€",
        pinta: "4,5mm",
        images: {
            image1: require("../components/images/10143/pic1.jpg"),
            image2: require("../components/images/10143/pic2.jpg"),
            image3: require("../components/images/10143/pic3.jpg"),
            //image4: require("../components/images/10143/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10144,
        model: "Michelin  Pilot Sport 3",
        size: "245/45/19",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "102Y",
        price: "150€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10144/pic1.jpg"),
            image2: require("../components/images/10144/pic2.jpg"),
            image3: require("../components/images/10144/pic3.jpg"),
            //image4: require("../components/images/10144/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10145,
        model: "Toyo Proxes T1 Sport",
        size: "225/55/17",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "97V",
        price: "150€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10145/pic1.jpg"),
            image2: require("../components/images/10145/pic2.jpg"),
            image3: require("../components/images/10145/pic3.jpg"),
            image4: require("../components/images/10145/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10146,
        model: "Triangle Sportex",
        size: "215/55/17",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "94W",
        price: "50€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10146/pic1.jpg"),
            image2: require("../components/images/10146/pic2.jpg"),
            image3: require("../components/images/10146/pic3.jpg"),
            image4: require("../components/images/10146/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10148,
        model: "Kumho Solus KH17",
        size: "215/50/17",
        season: "kesä",
        year: "2011",
        loadSpdIdx: "91V",
        price: "70€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10148/pic1.jpg"),
            image2: require("../components/images/10148/pic2.jpg"),
            image3: require("../components/images/10148/pic3.jpg"),
            //image4: require("../components/images/10148/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10149,
        model: "Hankook Winter I*cept Evo 2",
        size: "255/45/19",
        season: "kitka",
        year: "2020",
        loadSpdIdx: "104V",
        price: "170€",
        pinta: "5,5mm",
        images: {
            image1: require("../components/images/10149/pic1.jpg"),
            image2: require("../components/images/10149/pic2.jpg"),
            image3: require("../components/images/10149/pic3.jpg"),
            image4: require("../components/images/10149/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10150,
        model: "Michelin  Pilot Sport 4",
        size: "245/40/18",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "97Y",
        price: "80€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10150/pic1.jpg"),
            image2: require("../components/images/10150/pic2.jpg"),
            image3: require("../components/images/10150/pic3.jpg"),
            image4: require("../components/images/10150/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10151,
        model: "Michelin  Primacy 4",
        size: "225/50/17",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "98Y",
        price: "60€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10151/pic1.jpg"),
            image2: require("../components/images/10151/pic2.jpg"),
            image3: require("../components/images/10151/pic3.jpg"),
            image4: require("../components/images/10151/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10152,
        model: "Continental  ContiSportContact 3",
        size: "245/40/18",
        season: "kesä",
        year: "2016",
        loadSpdIdx: "97Y",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10152/pic1.jpg"),
            image2: require("../components/images/10152/pic2.jpg"),
            image3: require("../components/images/10152/pic3.jpg"),
            image4: require("../components/images/10152/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10153,
        model: "Hankook Ventus S1 Evo 2",
        size: "275/30/19",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "96Y",
        price: "100€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10153/pic1.jpg"),
            image2: require("../components/images/10153/pic2.jpg"),
            image3: require("../components/images/10153/pic3.jpg"),
            image4: require("../components/images/10153/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10154,
        model: "Bridgestone Turanza T005",
        size: "195/55/16",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "87H",
        price: "50€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10154/pic1.jpg"),
            image2: require("../components/images/10154/pic2.jpg"),
            image3: require("../components/images/10154/pic3.jpg"),
            image4: require("../components/images/10154/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10155,
        model: "Continental  EcoContact 6",
        size: "195/55/16",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "87V",
        price: "50€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10155/pic1.jpg"),
            image2: require("../components/images/10155/pic2.jpg"),
            image3: require("../components/images/10155/pic3.jpg"),
            //image4: require("../components/images/10155/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10156,
        model: "Michelin  Primacy 3",
        size: "245/45/18",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "100Y",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10156/pic1.jpg"),
            image2: require("../components/images/10156/pic2.jpg"),
            image3: require("../components/images/10156/pic3.jpg"),
            image4: require("../components/images/10156/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10157,
        model: "Arivo Ultra ARZ5",
        size: "275/30/20",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "97W",
        price: "100€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10157/pic1.jpg"),
            image2: require("../components/images/10157/pic2.jpg"),
            image3: require("../components/images/10157/pic3.jpg"),
            image4: require("../components/images/10157/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10158,
        model: "Michelin  Primacy 4",
        size: "195/65/15",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "91H",
        price: "50€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10158/pic1.jpg"),
            image2: require("../components/images/10158/pic2.jpg"),
            image3: require("../components/images/10158/pic3.jpg"),
            image4: require("../components/images/10158/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10159,
        model: "Michelin  Pilot Sport 4",
        size: "235/45/18",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10159/pic1.jpg"),
            image2: require("../components/images/10159/pic2.jpg"),
            image3: require("../components/images/10159/pic3.jpg"),
            image4: require("../components/images/10159/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10160,
        model: "Michelin  Pilot Sport 4",
        size: "235/45/18",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "98Y",
        price: "80€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10160/pic1.jpg"),
            image2: require("../components/images/10160/pic2.jpg"),
            image3: require("../components/images/10160/pic3.jpg"),
            image4: require("../components/images/10160/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10161,
        model: "Michelin  Primacy 4",
        size: "235/45/18",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "98W",
        price: "80€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10161/pic1.jpg"),
            image2: require("../components/images/10161/pic2.jpg"),
            image3: require("../components/images/10161/pic3.jpg"),
            image4: require("../components/images/10161/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10162,
        model: "Michelin  Primacy 4",
        size: "225/45/17",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "94V",
        price: "70€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10162/pic1.jpg"),
            image2: require("../components/images/10162/pic2.jpg"),
            image3: require("../components/images/10162/pic3.jpg"),
            //image4: require("../components/images/10162/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10163,
        model: "GoodYear Eagle F1 Asymmetric 3",
        size: "275/40/18",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "103Y",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10163/pic1.jpg"),
            image2: require("../components/images/10163/pic2.jpg"),
            image3: require("../components/images/10163/pic3.jpg"),
            image4: require("../components/images/10163/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10164,
        model: "Continental  ContiPremiumContact 2",
        size: "205/60/16",
        season: "kesä",
        year: "2014",
        loadSpdIdx: "92H",
        price: "70€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10164/pic1.jpg"),
            image2: require("../components/images/10164/pic2.jpg"),
            image3: require("../components/images/10164/pic3.jpg"),
            image4: require("../components/images/10164/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10165,
        model: "Bridgestone Ecopia EP150",
        size: "185/55/16",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "83V",
        price: "50€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10165/pic1.jpg"),
            image2: require("../components/images/10165/pic2.jpg"),
            image3: require("../components/images/10165/pic3.jpg"),
            image4: require("../components/images/10165/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10166,
        model: "GoodRide Radial RP28",
        size: "165/65/13",
        season: "kesä",
        year: "2016",
        loadSpdIdx: "77T",
        price: "50€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10166/pic1.jpg"),
            image2: require("../components/images/10166/pic2.jpg"),
            image3: require("../components/images/10166/pic3.jpg"),
            image4: require("../components/images/10166/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10167,
        model: "Michelin  Primacy 4",
        size: "255/45/20",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "",
        price: "80€",
        pinta: "4,5mm",
        images: {
            image1: require("../components/images/10167/pic1.jpg"),
            image2: require("../components/images/10167/pic2.jpg"),
            image3: require("../components/images/10167/pic3.jpg"),
            image4: require("../components/images/10167/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10168,
        model: "Continental  VanContact Eco",
        size: "215/60/17C",
        season: "kesä",
        year: "2023",
        loadSpdIdx: "107T",
        price: "100€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10168/pic1.jpg"),
            image2: require("../components/images/10168/pic2.jpg"),
            image3: require("../components/images/10168/pic3.jpg"),
            image4: require("../components/images/10168/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10169,
        model: "Nokian Hakka Blue 3",
        size: "215/55/17",
        season: "kesä",
        year: "2023",
        loadSpdIdx: "",
        price: "70€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10169/pic1.jpg"),
            image2: require("../components/images/10169/pic2.jpg"),
            image3: require("../components/images/10169/pic3.jpg"),
            //image4: require("../components/images/10169/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10170,
        model: "Nokian Nordman S SUV",
        size: "235/55/17",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "99H",
        price: "60€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10170/pic1.jpg"),
            image2: require("../components/images/10170/pic2.jpg"),
            image3: require("../components/images/10170/pic3.jpg"),
            image4: require("../components/images/10170/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10171,
        model: "Bridgestone Turanza T001",
        size: "215/60/16",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "95V",
        price: "40€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10171/pic1.jpg"),
            image2: require("../components/images/10171/pic2.jpg"),
            image3: require("../components/images/10171/pic3.jpg"),
            image4: require("../components/images/10171/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10172,
        model: "LingLong Green-Max 4X4 HP",
        size: "275/40/20",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "106V",
        price: "100€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10172/pic1.jpg"),
            image2: require("../components/images/10172/pic2.jpg"),
            image3: require("../components/images/10172/pic3.jpg"),
            image4: require("../components/images/10172/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10174,
        model: "Nordexx NS9100",
        size: "245/35/20",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "95Y",
        price: "100€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10174/pic1.jpg"),
            image2: require("../components/images/10174/pic2.jpg"),
            image3: require("../components/images/10174/pic3.jpg"),
            image4: require("../components/images/10174/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10175,
        model: "Dunlop Sport Maxx RT2",
        size: "245/35/19",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "93Y",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10175/pic1.jpg"),
            image2: require("../components/images/10175/pic2.jpg"),
            image3: require("../components/images/10175/pic3.jpg"),
            image4: require("../components/images/10175/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10176,
        model: "Triangle Advantex SUV",
        size: "235/50/18",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "97V",
        price: "100€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10176/pic1.jpg"),
            image2: require("../components/images/10176/pic2.jpg"),
            image3: require("../components/images/10176/pic3.jpg"),
            image4: require("../components/images/10176/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10177,
        model: "Michelin  Pilot Sport 4S",
        size: "245/35/20",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "95Y",
        price: "100€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10177/pic1.jpg"),
            image2: require("../components/images/10177/pic2.jpg"),
            image3: require("../components/images/10177/pic3.jpg"),
            image4: require("../components/images/10177/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10178,
        model: "Michelin  Pilot Sport 4S",
        size: "245/35/19",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "93Y",
        price: "100€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10178/pic1.jpg"),
            image2: require("../components/images/10178/pic2.jpg"),
            image3: require("../components/images/10178/pic3.jpg"),
            image4: require("../components/images/10178/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10179,
        model: "Tomason SportRace",
        size: "225/40/19",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "93Y",
        price: "100€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10179/pic1.jpg"),
            image2: require("../components/images/10179/pic2.jpg"),
            image3: require("../components/images/10179/pic3.jpg"),
            image4: require("../components/images/10179/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10180,
        model: "Michelin  Pilot Sport 4S",
        size: "265/40/20",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "104Y",
        price: "180€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10180/pic1.jpg"),
            image2: require("../components/images/10180/pic2.jpg"),
            image3: require("../components/images/10180/pic3.jpg"),
            image4: require("../components/images/10180/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10181,
        model: "Triangle Sportex TSH11",
        size: "205/55/16",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "91V",
        price: "70€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10181/pic1.jpg"),
            image2: require("../components/images/10181/pic2.jpg"),
            image3: require("../components/images/10181/pic3.jpg"),
            image4: require("../components/images/10181/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10182,
        model: "Triangle Sportex TSH11",
        size: "225/45/17",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "94Y",
        price: "150€",
        pinta: "4,5mm",
        images: {
            image1: require("../components/images/10182/pic1.jpg"),
            image2: require("../components/images/10182/pic2.jpg"),
            image3: require("../components/images/10182/pic3.jpg"),
            image4: require("../components/images/10182/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10183,
        model: "Continental  PremiumContact 6 SSR",
        size: "275/40/21",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "107Y",
        price: "100€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10183/pic1.jpg"),
            image2: require("../components/images/10183/pic2.jpg"),
            image3: require("../components/images/10183/pic3.jpg"),
            image4: require("../components/images/10183/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10184,
        model: "Continental  PremiumContact 6 SSR",
        size: "315/35/21",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "111Y",
        price: "100€",
        pinta: "3mm",
        images: {
            image1: require("../components/images/10184/pic1.jpg"),
            image2: require("../components/images/10184/pic2.jpg"),
            image3: require("../components/images/10184/pic3.jpg"),
            image4: require("../components/images/10184/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10185,
        model: "Platin RP420 Summer",
        size: "235/35/19",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "",
        price: "100€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10185/pic1.jpg"),
            image2: require("../components/images/10185/pic2.jpg"),
            image3: require("../components/images/10185/pic3.jpg"),
            //image4: require("../components/images/10185/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10186,
        model: "Minerva Radial F205",
        size: "225/45/17",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "94Y",
        price: "180€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10186/pic1.jpg"),
            image2: require("../components/images/10186/pic2.jpg"),
            image3: require("../components/images/10186/pic3.jpg"),
            image4: require("../components/images/10186/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10187,
        model: "LingLong SportMaster",
        size: "225/45/18",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "95Y",
        price: "100€",
        pinta: "5,5mm",
        images: {
            image1: require("../components/images/10187/pic1.jpg"),
            image2: require("../components/images/10187/pic2.jpg"),
            image3: require("../components/images/10187/pic3.jpg"),
            image4: require("../components/images/10187/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10188,
        model: "Michelin  Primacy 4",
        size: "215/60/17",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "96V",
        price: "70€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10188/pic1.jpg"),
            image2: require("../components/images/10188/pic2.jpg"),
            image3: require("../components/images/10188/pic3.jpg"),
            image4: require("../components/images/10188/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10189,
        model: "Continental  ContiEco Contact 5",
        size: "215/55/18",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "99V",
        price: "70€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10189/pic1.jpg"),
            image2: require("../components/images/10189/pic2.jpg"),
            image3: require("../components/images/10189/pic3.jpg"),
            image4: require("../components/images/10189/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10190,
        model: "MasterSteel SuperSport",
        size: "235/55/19",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "105W",
        price: "80€",
        pinta: "5,5mm",
        images: {
            image1: require("../components/images/10190/pic1.jpg"),
            image2: require("../components/images/10190/pic2.jpg"),
            image3: require("../components/images/10190/pic3.jpg"),
            image4: require("../components/images/10190/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10191,
        model: "Pirelli Scorpion Verde",
        size: "235/55/19",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "101V",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10191/pic1.jpg"),
            image2: require("../components/images/10191/pic2.jpg"),
            image3: require("../components/images/10191/pic3.jpg"),
            image4: require("../components/images/10191/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10192,
        model: "Hankook Ventus S1 Evo 2 SUV",
        size: "295/35/21",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "107Y",
        price: "70€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10192/pic1.jpg"),
            image2: require("../components/images/10192/pic2.jpg"),
            image3: require("../components/images/10192/pic3.jpg"),
            image4: require("../components/images/10192/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10193,
        model: "Triangle SporteX TH201",
        size: "245/45/20",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "103Y",
        price: "100€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10193/pic1.jpg"),
            image2: require("../components/images/10193/pic2.jpg"),
            image3: require("../components/images/10193/pic3.jpg"),
            image4: require("../components/images/10193/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10194,
        model: "Landsail  LS588 SUV",
        size: "245/45/20",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "103W",
        price: "100€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10194/pic1.jpg"),
            image2: require("../components/images/10194/pic2.jpg"),
            image3: require("../components/images/10194/pic3.jpg"),
            image4: require("../components/images/10194/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10195,
        model: "Bridgestone Turanza Eco",
        size: "225/65/17",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "102V",
        price: "60€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10195/pic1.jpg"),
            image2: require("../components/images/10195/pic2.jpg"),
            image3: require("../components/images/10195/pic3.jpg"),
            image4: require("../components/images/10195/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10196,
        model: "Continental  PremiumContact 6",
        size: "205/55/16",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "91V",
        price: "50€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10196/pic1.jpg"),
            image2: require("../components/images/10196/pic2.jpg"),
            image3: require("../components/images/10196/pic3.jpg"),
            image4: require("../components/images/10196/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10197,
        model: "Dunlop SP Sport Maxx GT",
        size: "275/40/20",
        season: "kesä",
        year: "2016",
        loadSpdIdx: "106W",
        price: "100€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10197/pic1.jpg"),
            image2: require("../components/images/10197/pic2.jpg"),
            image3: require("../components/images/10197/pic3.jpg"),
            image4: require("../components/images/10197/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10198,
        model: "Continental  SportContact 6",
        size: "265/35/22",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "102Y",
        price: "150€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10198/pic1.jpg"),
            image2: require("../components/images/10198/pic2.jpg"),
            image3: require("../components/images/10198/pic3.jpg"),
            image4: require("../components/images/10198/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10199,
        model: "Hankook Winter I*cept Evo 2 SUV",
        size: "275/40/20",
        season: "kitka",
        year: "2018",
        loadSpdIdx: "106V",
        price: "200€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10199/pic1.jpg"),
            image2: require("../components/images/10199/pic2.jpg"),
            image3: require("../components/images/10199/pic3.jpg"),
            image4: require("../components/images/10199/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10200,
        model: "Michelin  Pilot Sport 4S",
        size: "265/35/21",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "101Y",
        price: "100€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10200/pic1.jpg"),
            image2: require("../components/images/10200/pic2.jpg"),
            image3: require("../components/images/10200/pic3.jpg"),
            image4: require("../components/images/10200/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10201,
        model: "Michelin  Pilot Sport 4S",
        size: "255/35/20",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "97Y",
        price: "100€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10201/pic1.jpg"),
            image2: require("../components/images/10201/pic2.jpg"),
            image3: require("../components/images/10201/pic3.jpg"),
            image4: require("../components/images/10201/pic4.jpg")
            },
        quantity: 2,
        hidden: true
    },
    {
        id: 10202,
        model: "Pirelli Cinturato P7",
        size: "235/40/19",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "96W",
        price: "100€",
        pinta: "4,5mm",
        images: {
            image1: require("../components/images/10202/pic1.jpg"),
            image2: require("../components/images/10202/pic2.jpg"),
            image3: require("../components/images/10202/pic3.jpg"),
            image4: require("../components/images/10202/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10203,
        model: "Tomason SportRace",
        size: "225/45/17",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "94Y",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10203/pic1.jpg"),
            image2: require("../components/images/10203/pic2.jpg"),
            image3: require("../components/images/10203/pic3.jpg"),
            image4: require("../components/images/10203/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10204,
        model: "GoodYear Efficient Grip Performance",
        size: "225/55/17",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "97W",
        price: "80€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10204/pic1.jpg"),
            image2: require("../components/images/10204/pic2.jpg"),
            image3: require("../components/images/10204/pic3.jpg"),
            image4: require("../components/images/10204/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10205,
        model: "Dunlop SP Sport Maxx 050",
        size: "235/45/18",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "94Y",
        price: "60€",
        pinta: "3mm",
        images: {
            image1: require("../components/images/10205/pic1.jpg"),
            image2: require("../components/images/10205/pic2.jpg"),
            image3: require("../components/images/10205/pic3.jpg"),
            image4: require("../components/images/10205/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10206,
        model: "Nokian Hakka Green 3",
        size: "225/55/17",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "101V",
        price: "60€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10206/pic1.jpg"),
            image2: require("../components/images/10206/pic2.jpg"),
            image3: require("../components/images/10206/pic3.jpg"),
            image4: require("../components/images/10206/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10207,
        model: "Michelin  Agilis Camping",
        size: "215/70/15CP",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "109Q",
        price: "80€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10207/pic1.jpg"),
            image2: require("../components/images/10207/pic2.jpg"),
            image3: require("../components/images/10207/pic3.jpg"),
            image4: require("../components/images/10207/pic4.jpg")
            },
        quantity: 2,
        hidden: true
    },
    {
        id: 10208,
        model: "Bridgestone Duravis",
        size: "215/70/15C",
        season: "kesä",
        year: "2010",
        loadSpdIdx: "109S",
        price: "70€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10208/pic1.jpg"),
            image2: require("../components/images/10208/pic2.jpg"),
            image3: require("../components/images/10208/pic3.jpg"),
            image4: require("../components/images/10208/pic4.jpg")
            },
        quantity: 2,
        hidden: true
    },
    {
        id: 10209,
        model: "Bridgestone Turanza Eco",
        size: "255/50/19",
        season: "kesä",
        year: "2023",
        loadSpdIdx: "103T",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10209/pic1.jpg"),
            image2: require("../components/images/10209/pic2.jpg"),
            image3: require("../components/images/10209/pic3.jpg"),
            image4: require("../components/images/10209/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10210,
        model: "Pirelli P Zero",
        size: "255/40/21",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "102V",
        price: "100€",
        pinta: "5,2mm",
        images: {
            image1: require("../components/images/10210/pic1.jpg"),
            image2: require("../components/images/10210/pic2.jpg"),
            image3: require("../components/images/10210/pic3.jpg"),
            image4: require("../components/images/10210/pic4.jpg"),
            image5: require("../components/images/10210/pic5.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10211,
        model: "Pirelli P Zero",
        size: "285/35/20",
        season: "kesä",
        year: "2023",
        loadSpdIdx: "104Y",
        price: "100€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10211/pic1.jpg"),
            image2: require("../components/images/10211/pic2.jpg"),
            image3: require("../components/images/10211/pic3.jpg"),
            image4: require("../components/images/10211/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10212,
        model: "Hankook Ventus S1 Evo 3 EV T2",
        size: "255/45/19",
        season: "kesä",
        year: "2023",
        loadSpdIdx: "104W",
        price: "100€",
        pinta: "5,5mm",
        images: {
            image1: require("../components/images/10212/pic1.jpg"),
            image2: require("../components/images/10212/pic2.jpg"),
            image3: require("../components/images/10212/pic3.jpg"),
            image4: require("../components/images/10212/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10213,
        model: "Pirelli P Zero",
        size: "255/40/20",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "101Y",
        price: "100€",
        pinta: "5,5mm",
        images: {
            image1: require("../components/images/10213/pic1.jpg"),
            image2: require("../components/images/10213/pic2.jpg"),
            image3: require("../components/images/10213/pic3.jpg"),
            image4: require("../components/images/10213/pic4.jpg"),
            image5: require("../components/images/10213/pic5.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10214,
        model: "GoodYear Efficient Grip Performance",
        size: "225/50/17",
        season: "kesä",
        year: "2015",
        loadSpdIdx: "94W",
        price: "60€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10214/pic1.jpg"),
            image2: require("../components/images/10214/pic2.jpg"),
            image3: require("../components/images/10214/pic3.jpg"),
            image4: require("../components/images/10214/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10215,
        model: "Continental  ContiPremuim Contact 2",
        size: "205/55/16",
        season: "kesä",
        year: "2010",
        loadSpdIdx: "91V",
        price: "80€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10215/pic1.jpg"),
            image2: require("../components/images/10215/pic2.jpg"),
            image3: require("../components/images/10215/pic3.jpg"),
            image4: require("../components/images/10215/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10216,
        model: "Radar Dimax R8+",
        size: "225/35/19",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "88Y",
        price: "100€",
        pinta: "5,5mm",
        images: {
            image1: require("../components/images/10216/pic1.jpg"),
            image2: require("../components/images/10216/pic2.jpg"),
            image3: require("../components/images/10216/pic3.jpg"),
            image4: require("../components/images/10216/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10217,
        model: "Michelin  Pilot Sport 4S",
        size: "255/35/19",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "96Y",
        price: "100€",
        pinta: "4,8mm",
        images: {
            image1: require("../components/images/10217/pic1.jpg"),
            image2: require("../components/images/10217/pic2.jpg"),
            image3: require("../components/images/10217/pic3.jpg"),
            image4: require("../components/images/10217/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10218,
        model: "Michelin  X-Ice North 4",
        size: "215/60/16",
        season: "nasta",
        year: "2020",
        loadSpdIdx: "99T",
        price: "60€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10218/pic1.jpg"),
            image2: require("../components/images/10218/pic2.jpg"),
            image3: require("../components/images/10218/pic3.jpg"),
            image4: require("../components/images/10218/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10219,
        model: "Radar Dimax R8+",
        size: "255/40/20",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "101Y",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10219/pic1.jpg"),
            image2: require("../components/images/10219/pic2.jpg"),
            image3: require("../components/images/10219/pic3.jpg"),
            image4: require("../components/images/10219/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10220,
        model: "Michelin Pilot Sport 4 SUV",
        size: "235/60/18",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "101Y",
        price: "100€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10220/pic1.jpg"),
            image2: require("../components/images/10220/pic2.jpg"),
            image3: require("../components/images/10220/pic3.jpg"),
            image4: require("../components/images/10220/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10221,
        model: "Continental  SportContact 7",
        size: "245/40/18",
        season: "kesä",
        year: "2023",
        loadSpdIdx: "97Y",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10221/pic1.jpg"),
            image2: require("../components/images/10221/pic2.jpg"),
            image3: require("../components/images/10221/pic3.jpg"),
            image4: require("../components/images/10221/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10222,
        model: "Linglong Sportmaster ",
        size: "245/45/19",
        season: "kesä",
        year: "2023",
        loadSpdIdx: "102Y",
        price: "60€",
        pinta: "4,2mm",
        images: {
            image1: require("../components/images/10222/pic1.jpg"),
            image2: require("../components/images/10222/pic2.jpg"),
            image3: require("../components/images/10222/pic3.jpg"),
            image4: require("../components/images/10222/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10223,
        model: "GoodYear Eagle F1 Asymmetric 3",
        size: "215/45/18",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "89V",
        price: "80€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10223/pic1.jpg"),
            image2: require("../components/images/10223/pic2.jpg"),
            image3: require("../components/images/10223/pic3.jpg"),
            image4: require("../components/images/10223/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10224,
        model: "Continental  ContiPremium Contact 5 SSR",
        size: "205/60/16",
        season: "kesä",
        year: "2015",
        loadSpdIdx: "92V",
        price: "50€",
        pinta: "4,5mm",
        images: {
            image1: require("../components/images/10224/pic1.jpg"),
            image2: require("../components/images/10224/pic2.jpg"),
            image3: require("../components/images/10224/pic3.jpg"),
            image4: require("../components/images/10224/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10225,
        model: "Hankook  Ventus S1 Evo 3",
        size: "245/35/20",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "95Y",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10225/pic1.jpg"),
            image2: require("../components/images/10225/pic2.jpg"),
            image3: require("../components/images/10225/pic3.jpg"),
            image4: require("../components/images/10225/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10226,
        model: "Minerva  Transport RF19",
        size: "225/65/16C",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "112/110T",
        price: "100€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10226/pic1.jpg"),
            image2: require("../components/images/10226/pic2.jpg"),
            image3: require("../components/images/10226/pic3.jpg"),
            image4: require("../components/images/10226/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10227,
        model: "Tomason SportRace",
        size: "225/40/18",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "92Y",
        price: "80€",
        pinta: "4,8mm",
        images: {
            image1: require("../components/images/10227/pic1.jpg"),
            image2: require("../components/images/10227/pic2.jpg"),
            image3: require("../components/images/10227/pic3.jpg"),
            image4: require("../components/images/10227/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10229,
        model: "Nokian Hakkapeliitta 9",
        size: "225/45/17",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "94T",
        price: "200€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10229/pic1.jpg"),
            image2: require("../components/images/10229/pic2.jpg"),
            image3: require("../components/images/10229/pic3.jpg"),
            image4: require("../components/images/10229/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10230,
        model: "Michelin Primacy 4",
        size: "225/50/17",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "98V",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10230/pic1.jpg"),
            image2: require("../components/images/10230/pic2.jpg"),
            image3: require("../components/images/10230/pic3.jpg"),
            image4: require("../components/images/10230/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10231,
        model: "Hankook Kinergy Eco 2",
        size: "205/55/16",
        season: "kesä",
        year: "2023",
        loadSpdIdx: "91H",
        price: "80€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10231/pic1.jpg"),
            image2: require("../components/images/10231/pic2.jpg"),
            image3: require("../components/images/10231/pic3.jpg"),
            image4: require("../components/images/10231/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10232,
        model: "Dunlop Sport Maxx RT",
        size: "225/45/17",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "91W",
        price: "80€",
        pinta: "4,5mm",
        images: {
            image1: require("../components/images/10232/pic1.jpg"),
            image2: require("../components/images/10232/pic2.jpg"),
            image3: require("../components/images/10232/pic3.jpg"),
            image4: require("../components/images/10232/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10233,
        model: "Continental  Premium Contact 6",
        size: "205/55/16",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "91V",
        price: "40€",
        pinta: "4,5mm",
        images: {
            image1: require("../components/images/10233/pic1.jpg"),
            image2: require("../components/images/10233/pic2.jpg"),
            image3: require("../components/images/10233/pic3.jpg"),
            image4: require("../components/images/10233/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10234,
        model: "Michelin Energy Saver",
        size: "205/60/16",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "92V",
        price: "40€",
        pinta: "4,5mm",
        images: {
            image1: require("../components/images/10234/pic1.jpg"),
            image2: require("../components/images/10234/pic2.jpg"),
            image3: require("../components/images/10234/pic3.jpg"),
            image4: require("../components/images/10234/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10235,
        model: "Michelin Primacy 4",
        size: "215/55/18",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "99V",
        price: "80€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10235/pic1.jpg"),
            image2: require("../components/images/10235/pic2.jpg"),
            image3: require("../components/images/10235/pic3.jpg"),
            image4: require("../components/images/10235/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10236,
        model: "Yokohama BluEarth E70",
        size: "225/55/18",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "98H",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10236/pic1.jpg"),
            image2: require("../components/images/10236/pic2.jpg"),
            image3: require("../components/images/10236/pic3.jpg"),
            image4: require("../components/images/10236/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10237,
        model: "Nokian Hakkapeliitta 9",
        size: "205/55/16",
        season: "nasta",
        year: "2019",
        loadSpdIdx: "94T",
        price: "50€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10237/pic1.jpg"),
            image2: require("../components/images/10237/pic2.jpg"),
            image3: require("../components/images/10237/pic3.jpg"),
            image4: require("../components/images/10237/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10238,
        model: "Continental  PremiumContact 6",
        size: "235/40/18",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "95Y",
        price: "80€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10238/pic1.jpg"),
            image2: require("../components/images/10238/pic2.jpg"),
            image3: require("../components/images/10238/pic3.jpg"),
            image4: require("../components/images/10238/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10239,
        model: "Barum Bravuris 5 HM",
        size: "215/50/17",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "95Y",
        price: "130€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10239/pic1.jpg"),
            image2: require("../components/images/10239/pic2.jpg"),
            image3: require("../components/images/10239/pic3.jpg"),
            image4: require("../components/images/10239/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10240,
        model: "Continental  IceContact 2",
        size: "205/55/16",
        season: "nasta",
        year: "2017",
        loadSpdIdx: "94T",
        price: "70€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10240/pic1.jpg"),
            image2: require("../components/images/10240/pic2.jpg"),
            image3: require("../components/images/10240/pic3.jpg"),
            image4: require("../components/images/10240/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10241,
        model: "Nokian Hakkapeliitta 8",
        size: "185/60/15",
        season: "nasta",
        year: "2016",
        loadSpdIdx: "88T",
        price: "100€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10241/pic1.jpg"),
            image2: require("../components/images/10241/pic2.jpg"),
            image3: require("../components/images/10241/pic3.jpg"),
            image4: require("../components/images/10241/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10242,
        model: "Michelin Pilot Sport 4S",
        size: "255/35/19",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "96Y",
        price: "100€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10242/pic1.jpg"),
            image2: require("../components/images/10242/pic2.jpg"),
            image3: require("../components/images/10242/pic3.jpg"),
            image4: require("../components/images/10242/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10243,
        model: "Triangle EffeX Sport TH202",
        size: "235/35/19",
        season: "kesä",
        year: "2023",
        loadSpdIdx: "91Y",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10243/pic1.jpg"),
            image2: require("../components/images/10243/pic2.jpg"),
            image3: require("../components/images/10243/pic3.jpg"),
            image4: require("../components/images/10243/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10244,
        model: "GoodYear Efficient Grip RunOnFlat",
        size: "245/50/18",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "100W",
        price: "100€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10244/pic1.jpg"),
            image2: require("../components/images/10244/pic2.jpg"),
            image3: require("../components/images/10244/pic3.jpg"),
            image4: require("../components/images/10244/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10245,
        model: "Michelin Pilot Sport 4 SUV",
        size: "235/50/19",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "99V",
        price: "130€",
        pinta: "5,5mm",
        images: {
            image1: require("../components/images/10245/pic1.jpg"),
            image2: require("../components/images/10245/pic2.jpg"),
            image3: require("../components/images/10245/pic3.jpg"),
            image4: require("../components/images/10245/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10246,
        model: "Michelin Primacy 4",
        size: "225/45/17",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "91W",
        price: "80€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10246/pic1.jpg"),
            image2: require("../components/images/10246/pic2.jpg"),
            image3: require("../components/images/10246/pic3.jpg"),
            image4: require("../components/images/10246/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10247,
        model: "Triangle WinterX TW401",
        size: "225/50/17",
        season: "kitka",
        year: "2023",
        loadSpdIdx: "98V",
        price: "80€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10247/pic1.jpg"),
            image2: require("../components/images/10247/pic2.jpg"),
            image3: require("../components/images/10247/pic3.jpg"),
            image4: require("../components/images/10247/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10248,
        model: "Michelin Primacy 4",
        size: "235/50/19",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "103V",
        price: "160€",
        pinta: "5,5mm",
        images: {
            image1: require("../components/images/10248/pic1.jpg"),
            image2: require("../components/images/10248/pic2.jpg"),
            image3: require("../components/images/10248/pic3.jpg"),
            image4: require("../components/images/10248/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10249,
        model: "Gislaved NordFrost 200",
        size: "225/45/17",
        season: "nasta",
        year: "2019",
        loadSpdIdx: "94T",
        price: "150€",
        pinta: "6-7mm",
        images: {
            image1: require("../components/images/10249/pic1.jpg"),
            image2: require("../components/images/10249/pic2.jpg"),
            image3: require("../components/images/10249/pic3.jpg"),
            image4: require("../components/images/10249/pic4.jpg"),
            image5: require("../components/images/10249/pic5.jpg")
            },
        quantity: 4,
        hidden: true
    },
    {
        id: 10250,
        model: "Michelin X-Ice North 4 SUV",
        size: "235/55/19",
        season: "nasta",
        year: "2019",
        loadSpdIdx: "105T",
        price: "150€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10250/pic1.jpg"),
            image2: require("../components/images/10250/pic2.jpg"),
            image3: require("../components/images/10250/pic3.jpg"),
            image4: require("../components/images/10250/pic4.jpg"),
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10251,
        model: "Pirelli Ice Zero FR",
        size: "215/55/17",
        season: "kitka",
        year: "2020",
        loadSpdIdx: "98H",
        price: "150€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10251/pic1.jpg"),
            image2: require("../components/images/10251/pic2.jpg"),
            image3: require("../components/images/10251/pic3.jpg"),
            image4: require("../components/images/10251/pic4.jpg"),
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10252,
        model: "GoodYear Efficient Grip Performance",
        size: "205/60/16",
        season: "kesä",
        year: "2014",
        loadSpdIdx: "92H",
        price: "50€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10252/pic1.jpg"),
            image2: require("../components/images/10252/pic2.jpg"),
            image3: require("../components/images/10252/pic3.jpg"),
            image4: require("../components/images/10252/pic4.jpg"),
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10253,
        model: "Falken Ziex ZE914B",
        size: "225/45/17",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "91W",
        price: "100€",
        pinta: "4-5,5mm",
        images: {
            image1: require("../components/images/10253/pic1.jpg"),
            image2: require("../components/images/10253/pic2.jpg"),
            image3: require("../components/images/10253/pic3.jpg"),
            image4: require("../components/images/10253/pic4.jpg"),
            image5: require("../components/images/10253/pic5.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10254,
        model: "Bridgestone Turanza T005",
        size: "205/55/16",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "91V",
        price: "70€",
        pinta: "5,5mm",
        images: {
            image1: require("../components/images/10254/pic1.jpg"),
            image2: require("../components/images/10254/pic2.jpg"),
            image3: require("../components/images/10254/pic3.jpg"),
            image4: require("../components/images/10254/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10255,
        model: "Sailun Atrezzo Elite",
        size: "205/55/16",
        season: "kesä",
        year: "2023",
        loadSpdIdx: "91V",
        price: "80€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10255/pic1.jpg"),
            image2: require("../components/images/10255/pic2.jpg"),
            image3: require("../components/images/10255/pic3.jpg"),
            image4: require("../components/images/10255/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10256,
        model: "Continental  IceContact 2",
        size: "225/55/17",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "101T",
        price: "150€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10256/pic1.jpg"),
            image2: require("../components/images/10256/pic2.jpg"),
            image3: require("../components/images/10256/pic3.jpg"),
            image4: require("../components/images/10256/pic4.jpg"),
            image5: require("../components/images/10256/pic5.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10257,
        model: "Event Potentem UHP",
        size: "235/45/18",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "98W",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10257/pic1.jpg"),
            image2: require("../components/images/10257/pic2.jpg"),
            image3: require("../components/images/10257/pic3.jpg"),
            image4: require("../components/images/10257/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10258,
        model: "Continental  ContiVikingContact 6",
        size: "225/50/17",
        season: "kitka",
        year: "2017",
        loadSpdIdx: "98T",
        price: "100€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10258/pic1.jpg"),
            image2: require("../components/images/10258/pic2.jpg"),
            image3: require("../components/images/10258/pic3.jpg"),
            image4: require("../components/images/10258/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10259,
        model: "Continental  IceContact 2",
        size: "225/50/17",
        season: "nasta",
        year: "2015",
        loadSpdIdx: "98T",
        price: "130€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10259/pic1.jpg"),
            image2: require("../components/images/10259/pic2.jpg"),
            image3: require("../components/images/10259/pic3.jpg"),
            image4: require("../components/images/10259/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10260,
        model: "Continental  IceContact 2",
        size: "215/60/17",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "96T",
        price: "100€",
        pinta: "5-6mm",
        images: {
            image1: require("../components/images/10260/pic1.jpg"),
            image2: require("../components/images/10260/pic2.jpg"),
            image3: require("../components/images/10260/pic3.jpg"),
            image4: require("../components/images/10260/pic4.jpg"),
            image5: require("../components/images/10260/pic5.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10261,
        model: "Bridgestone Noranza",
        size: "215/65/16C",
        season: "nasta",
        year: "2017",
        loadSpdIdx: "109/107R",
        price: "180€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10261/pic1.jpg"),
            image2: require("../components/images/10261/pic2.jpg"),
            image3: require("../components/images/10261/pic3.jpg"),
            image4: require("../components/images/10261/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10262,
        model: "Michelin X-Ice North 3",
        size: "205/55/16",
        season: "nasta",
        year: "2014",
        loadSpdIdx: "94T",
        price: "150€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10262/pic1.jpg"),
            image2: require("../components/images/10262/pic2.jpg"),
            image3: require("../components/images/10262/pic3.jpg"),
            image4: require("../components/images/10262/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10263,
        model: "Nokian Hakkapeliitta R3",
        size: "235/45/18",
        season: "kitka",
        year: "2019",
        loadSpdIdx: "98T",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10263/pic1.jpg"),
            image2: require("../components/images/10263/pic2.jpg"),
            image3: require("../components/images/10263/pic3.jpg"),
            image4: require("../components/images/10263/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10264,
        model: "Nokian Hakkapeliitta 9",
        size: "205/55/16",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "94T",
        price: "130€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10264/pic1.jpg"),
            image2: require("../components/images/10264/pic2.jpg"),
            image3: require("../components/images/10264/pic3.jpg"),
            image4: require("../components/images/10264/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10265,
        model: "Winrun Maxclaw H/T2",
        size: "225/65/17",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "102T",
        price: "150€",
        pinta: "8,5mm",
        images: {
            image1: require("../components/images/10265/pic1.jpg"),
            image2: require("../components/images/10265/pic2.jpg"),
            image3: require("../components/images/10265/pic3.jpg"),
            image4: require("../components/images/10265/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10266,
        model: "Nokian Hakkapeliitta 8",
        size: "205/55/16",
        season: "nasta",
        year: "2015",
        loadSpdIdx: "94T",
        price: "70€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10266/pic1.jpg"),
            image2: require("../components/images/10266/pic2.jpg"),
            image3: require("../components/images/10266/pic3.jpg"),
            image4: require("../components/images/10266/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10267,
        model: "Nokian Hakkapeliitta 9",
        size: "215/60/16",
        season: "nasta",
        year: "2019",
        loadSpdIdx: "99T",
        price: "80€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10267/pic1.jpg"),
            image2: require("../components/images/10267/pic2.jpg"),
            image3: require("../components/images/10267/pic3.jpg"),
            image4: require("../components/images/10267/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10268,
        model: "Continental IceContact 3",
        size: "205/55/16",
        season: "nasta",
        year: "2019",
        loadSpdIdx: "94T",
        price: "70€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10268/pic1.jpg"),
            image2: require("../components/images/10268/pic2.jpg"),
            image3: require("../components/images/10268/pic3.jpg"),
            image4: require("../components/images/10268/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10269,
        model: "Nokian Hakkapeliitta C4",
        size: "215/60/17C",
        season: "nasta",
        year: "2022",
        loadSpdIdx: "109/107R",
        price: "100€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10269/pic1.jpg"),
            image2: require("../components/images/10269/pic2.jpg"),
            image3: require("../components/images/10269/pic3.jpg"),
            image4: require("../components/images/10269/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10270,
        model: "Continental IceContact 2",
        size: "225/45/17",
        season: "nasta",
        year: "2016",
        loadSpdIdx: "94T",
        price: "100€",
        pinta: "5,5mm",
        images: {
            image1: require("../components/images/10270/pic1.jpg"),
            image2: require("../components/images/10270/pic2.jpg"),
            image3: require("../components/images/10270/pic3.jpg"),
            image4: require("../components/images/10270/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10271,
        model: "Nokian Hakkapeliitta 9 SUV",
        size: "215/60/17",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "100T",
        price: "200€",
        pinta: "10mm",
        images: {
            image1: require("../components/images/10271/pic1.jpg"),
            image2: require("../components/images/10271/pic2.jpg"),
            image3: require("../components/images/10271/pic3.jpg"),
            image4: require("../components/images/10271/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10272,
        model: "Michelin X-Ice North 4",
        size: "195/65/15",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "95T",
        price: "100€",
        pinta: "5-6mm",
        images: {
            image1: require("../components/images/10272/pic1.jpg"),
            image2: require("../components/images/10272/pic2.jpg"),
            image3: require("../components/images/10272/pic3.jpg"),
            image4: require("../components/images/10272/pic4.jpg"),
            image5: require("../components/images/10272/pic5.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10273,
        model: "Michelin X-Ice North 4",
        size: "205/55/16",
        season: "nasta",
        year: "2021",
        loadSpdIdx: "95T",
        price: "130€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10273/pic1.jpg"),
            image2: require("../components/images/10273/pic2.jpg"),
            image3: require("../components/images/10273/pic3.jpg"),
            image4: require("../components/images/10273/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10274,
        model: "Continental IceContact 2",
        size: "235/60/18",
        season: "nasta",
        year: "2017",
        loadSpdIdx: "107T",
        price: "150€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10274/pic1.jpg"),
            image2: require("../components/images/10274/pic2.jpg"),
            image3: require("../components/images/10274/pic3.jpg"),
            image4: require("../components/images/10274/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10275,
        model: "Continental VikingContact 7",
        size: "235/60/18",
        season: "kitka",
        year: "2020",
        loadSpdIdx: "107T",
        price: "100€",
        pinta: "5,5mm",
        images: {
            image1: require("../components/images/10275/pic1.jpg"),
            image2: require("../components/images/10275/pic2.jpg"),
            image3: require("../components/images/10275/pic3.jpg"),
            image4: require("../components/images/10275/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10276,
        model: "Continental IceContact 2",
        size: "185/65/15",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "92T",
        price: "50€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10276/pic1.jpg"),
            image2: require("../components/images/10276/pic2.jpg"),
            image3: require("../components/images/10276/pic3.jpg"),
            image4: require("../components/images/10276/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10277,
        model: "Nokian Hakkapeliitta 9",
        size: "235/45/18",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "98T",
        price: "100€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10277/pic1.jpg"),
            image2: require("../components/images/10277/pic2.jpg"),
            image3: require("../components/images/10277/pic3.jpg"),
            image4: require("../components/images/10277/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10278,
        model: "Michelin X-Ice North 4",
        size: "245/40/18",
        season: "nasta",
        year: "2020",
        loadSpdIdx: "97T",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10278/pic1.jpg"),
            image2: require("../components/images/10278/pic2.jpg"),
            image3: require("../components/images/10278/pic3.jpg"),
            image4: require("../components/images/10278/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10279,
        model: "Nokian Hakkapeliitta 8",
        size: "245/45/17",
        season: "nasta",
        year: "2016",
        loadSpdIdx: "99T",
        price: "80€",
        pinta: "9mm",
        images: {
            image1: require("../components/images/10279/pic1.jpg"),
            image2: require("../components/images/10279/pic2.jpg"),
            image3: require("../components/images/10279/pic3.jpg"),
            image4: require("../components/images/10279/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10280,
        model: "Continental IceContact 2",
        size: "185/65/15",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "92T",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10280/pic1.jpg"),
            image2: require("../components/images/10280/pic2.jpg"),
            image3: require("../components/images/10280/pic3.jpg"),
            image4: require("../components/images/10280/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10281,
        model: "Nokian Hakkapeliitta 8",
        size: "175/65/15",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "88T",
        price: "80€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10281/pic1.jpg"),
            image2: require("../components/images/10281/pic2.jpg"),
            image3: require("../components/images/10281/pic3.jpg"),
            image4: require("../components/images/10281/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10282,
        model: "Nokian Hakkapeliitta 8 SUV",
        size: "235/55/18",
        season: "nasta",
        year: "2017",
        loadSpdIdx: "104T",
        price: "150€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10282/pic1.jpg"),
            image2: require("../components/images/10282/pic2.jpg"),
            image3: require("../components/images/10282/pic3.jpg"),
            image4: require("../components/images/10282/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10283,
        model: "Bridgestone Noranza 001",
        size: "215/75/16C",
        season: "nasta",
        year: "2016",
        loadSpdIdx: "116/114R",
        price: "150€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10283/pic1.jpg"),
            image2: require("../components/images/10283/pic2.jpg"),
            image3: require("../components/images/10283/pic3.jpg"),
            image4: require("../components/images/10283/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10284,
        model: "Continental IceContact 2",
        size: "225/50/17",
        season: "nasta",
        year: "2015",
        loadSpdIdx: "98T",
        price: "130€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10284/pic1.jpg"),
            image2: require("../components/images/10284/pic2.jpg"),
            image3: require("../components/images/10284/pic3.jpg"),
            image4: require("../components/images/10284/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10285,
        model: "GoodYear UltraGrip Arctic 2",
        size: "235/55/18",
        season: "kitka",
        year: "2021",
        loadSpdIdx: "104T",
        price: "200€",
        pinta: "9mm",
        images: {
            image1: require("../components/images/10285/pic1.jpg"),
            image2: require("../components/images/10285/pic2.jpg"),
            image3: require("../components/images/10285/pic3.jpg"),
            image4: require("../components/images/10285/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10286,
        model: "Nokian Hakkapeliitta R2",
        size: "245/45/19",
        season: "kitka",
        year: "2017",
        loadSpdIdx: "102R",
        price: "130€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10286/pic1.jpg"),
            image2: require("../components/images/10286/pic2.jpg"),
            image3: require("../components/images/10286/pic3.jpg"),
            image4: require("../components/images/10286/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10287,
        model: "Nokian Hakkapeliitta 9 SUV",
        size: "225/60/18",
        season: "nasta",
        year: "2021",
        loadSpdIdx: "104T",
        price: "100€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10287/pic1.jpg"),
            image2: require("../components/images/10287/pic2.jpg"),
            image3: require("../components/images/10287/pic3.jpg"),
            image4: require("../components/images/10287/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10288,
        model: "Yokohama IceGuard IG60",
        size: "245/45/18",
        season: "kitka",
        year: "2018",
        loadSpdIdx: "100Q",
        price: "80€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10288/pic1.jpg"),
            image2: require("../components/images/10288/pic2.jpg"),
            image3: require("../components/images/10288/pic3.jpg"),
            image4: require("../components/images/10288/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10289,
        model: "Kumho WinterCraft WS51 SUV",
        size: "215/60/17",
        season: "kitka",
        year: "2018",
        loadSpdIdx: "100T",
        price: "80€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10289/pic1.jpg"),
            image2: require("../components/images/10289/pic2.jpg"),
            image3: require("../components/images/10289/pic3.jpg"),
            image4: require("../components/images/10289/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10290,
        model: "Nokian Hakkapeliitta 8",
        size: "205/60/16",
        season: "nasta",
        year: "2017",
        loadSpdIdx: "96T",
        price: "80€",
        pinta: "6,8mm",
        images: {
            image1: require("../components/images/10290/pic1.jpg"),
            image2: require("../components/images/10290/pic2.jpg"),
            image3: require("../components/images/10290/pic3.jpg"),
            image4: require("../components/images/10290/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10291,
        model: "Nokian Hakkapeliitta C4",
        size: "215/65/16C",
        season: "nasta",
        year: "2022",
        loadSpdIdx: "109/107R",
        price: "70€",
        pinta: "6,2mm",
        images: {
            image1: require("../components/images/10291/pic1.jpg"),
            image2: require("../components/images/10291/pic2.jpg"),
            image3: require("../components/images/10291/pic3.jpg"),
            image4: require("../components/images/10291/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10292,
        model: "Nokian Hakkapeliitta R3",
        size: "205/55/16",
        season: "kitka",
        year: "2019",
        loadSpdIdx: "94R",
        price: "80€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10292/pic1.jpg"),
            image2: require("../components/images/10292/pic2.jpg"),
            image3: require("../components/images/10292/pic3.jpg"),
            image4: require("../components/images/10292/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10293,
        model: "Nokian Hakkapeliitta 8",
        size: "195/55/16",
        season: "nasta",
        year: "2019",
        loadSpdIdx: "91T",
        price: "60€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10293/pic1.jpg"),
            image2: require("../components/images/10293/pic2.jpg"),
            image3: require("../components/images/10293/pic3.jpg"),
            image4: require("../components/images/10293/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10294,
        model: "Nankang Ice Activa SW-8",
        size: "225/45/17",
        season: "nasta",
        year: "2017",
        loadSpdIdx: "94T",
        price: "80€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10294/pic1.jpg"),
            image2: require("../components/images/10294/pic2.jpg"),
            image3: require("../components/images/10294/pic3.jpg"),
            image4: require("../components/images/10294/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10295,
        model: "Continental  IceContact 2",
        size: "195/55/16",
        season: "nasta",
        year: "2019",
        loadSpdIdx: "91T",
        price: "70€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10295/pic1.jpg"),
            image2: require("../components/images/10295/pic2.jpg"),
            image3: require("../components/images/10295/pic3.jpg"),
            image4: require("../components/images/10295/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10296,
        model: "Sailun Ice Blazer WST3",
        size: "245/45/18",
        season: "nasta",
        year: "2022",
        loadSpdIdx: "100T",
        price: "60€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10296/pic1.jpg"),
            image2: require("../components/images/10296/pic2.jpg"),
            image3: require("../components/images/10296/pic3.jpg"),
            image4: require("../components/images/10296/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10297,
        model: "Continental  IceContact 2",
        size: "215/55/17",
        season: "nasta",
        year: "2017",
        loadSpdIdx: "98T",
        price: "160€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10297/pic1.jpg"),
            image2: require("../components/images/10297/pic2.jpg"),
            image3: require("../components/images/10297/pic3.jpg"),
            image4: require("../components/images/10297/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10298,
        model: "Nokian Hakkapeliitta 8",
        size: "205/60/16",
        season: "nasta",
        year: "2014",
        loadSpdIdx: "96T",
        price: "75€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10298/pic1.jpg"),
            image2: require("../components/images/10298/pic2.jpg"),
            image3: require("../components/images/10298/pic3.jpg"),
            image4: require("../components/images/10298/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10299,
        model: "Nokian Hakkapeliitta C3",
        size: "215/65/16C",
        season: "nasta",
        year: "2016",
        loadSpdIdx: "109/107R",
        price: "70€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10299/pic1.jpg"),
            image2: require("../components/images/10299/pic2.jpg"),
            image3: require("../components/images/10299/pic3.jpg"),
            image4: require("../components/images/10299/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10300,
        model: "Continental WinterContact TS 860S",
        size: "265/35/20",
        season: "kitka",
        year: "2019",
        loadSpdIdx: "99W",
        price: "100€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10300/pic1.jpg"),
            image2: require("../components/images/10300/pic2.jpg"),
            image3: require("../components/images/10300/pic3.jpg"),
            image4: require("../components/images/10300/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10301,
        model: "Continental WinterContact 7",
        size: "225/60/18",
        season: "kitka",
        year: "2020",
        loadSpdIdx: "104T",
        price: "80€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10301/pic1.jpg"),
            image2: require("../components/images/10301/pic2.jpg"),
            image3: require("../components/images/10301/pic3.jpg"),
            image4: require("../components/images/10301/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10302,
        model: "Michelin Primacy 3",
        size: "215/55/18",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "99V",
        price: "80€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10302/pic1.jpg"),
            image2: require("../components/images/10302/pic2.jpg"),
            image3: require("../components/images/10302/pic3.jpg"),
            image4: require("../components/images/10302/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10303,
        model: "MasterSteel SuperSport",
        size: "225/45/18",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "95W",
        price: "180€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10303/pic1.jpg"),
            image2: require("../components/images/10303/pic2.jpg"),
            image3: require("../components/images/10303/pic3.jpg"),
            image4: require("../components/images/10303/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10304,
        model: "Sailun Atrezzo Eco",
        size: "175/65/15",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "88T",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10304/pic1.jpg"),
            image2: require("../components/images/10304/pic2.jpg"),
            image3: require("../components/images/10304/pic3.jpg"),
            image4: require("../components/images/10304/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10305,
        model: "Leao Winter Defender Grip 2",
        size: "205/55/16",
        season: "nasta",
        year: "2021",
        loadSpdIdx: "94T",
        price: "60€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10305/pic1.jpg"),
            image2: require("../components/images/10305/pic2.jpg"),
            image3: require("../components/images/10305/pic3.jpg"),
            image4: require("../components/images/10305/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10306,
        model: "Nokian Hakkapeliitta 8",
        size: "225/55/17",
        season: "nasta",
        year: "2014",
        loadSpdIdx: "97T",
        price: "100€",
        pinta: "6-7mm",
        images: {
            image1: require("../components/images/10306/pic1.jpg"),
            image2: require("../components/images/10306/pic2.jpg"),
            image3: require("../components/images/10306/pic3.jpg"),
            image4: require("../components/images/10306/pic4.jpg"),
            image5: require("../components/images/10306/pic5.jpg"),
            image6: require("../components/images/10306/pic6.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10307,
        model: "Continental ContiVikingContact 6",
        size: "225/55/17",
        season: "kitka",
        year: "2017",
        loadSpdIdx: "101T",
        price: "170€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10307/pic1.jpg"),
            image2: require("../components/images/10307/pic2.jpg"),
            image3: require("../components/images/10307/pic3.jpg"),
            image4: require("../components/images/10307/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10308,
        model: "GoodYear UtraGrip Ice 2",
        size: "205/55/16",
        season: "kitka",
        year: "2019",
        loadSpdIdx: "94T",
        price: "120€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10308/pic1.jpg"),
            image2: require("../components/images/10308/pic2.jpg"),
            image3: require("../components/images/10308/pic3.jpg"),
            image4: require("../components/images/10308/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10309,
        model: "Michelin X-Ice North 4",
        size: "205/55/16",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "94T",
        price: "120€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10309/pic1.jpg"),
            image2: require("../components/images/10309/pic2.jpg"),
            image3: require("../components/images/10309/pic3.jpg"),
            image4: require("../components/images/10309/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10310,
        model: "Michelin X-Ice North 4",
        size: "205/55/16",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "94T",
        price: "60€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10310/pic1.jpg"),
            image2: require("../components/images/10310/pic2.jpg"),
            image3: require("../components/images/10310/pic3.jpg"),
            image4: require("../components/images/10310/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10311,
        model: "Continental IceContact 3",
        size: "235/55/19",
        season: "nasta",
        year: "2021",
        loadSpdIdx: "105T",
        price: "100€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10311/pic1.jpg"),
            image2: require("../components/images/10311/pic2.jpg"),
            image3: require("../components/images/10311/pic3.jpg"),
            image4: require("../components/images/10311/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10312,
        model: "Continental IceContact 7",
        size: "245/45/18",
        season: "kitka",
        year: "2021",
        loadSpdIdx: "100T",
        price: "130€",
        pinta: "6,2mm",
        images: {
            image1: require("../components/images/10312/pic1.jpg"),
            image2: require("../components/images/10312/pic2.jpg"),
            image3: require("../components/images/10312/pic3.jpg"),
            image4: require("../components/images/10312/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10313,
        model: "Continental IceContact 7",
        size: "235/45/18",
        season: "kitka",
        year: "2019",
        loadSpdIdx: "98T",
        price: "80€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10313/pic1.jpg"),
            image2: require("../components/images/10313/pic2.jpg"),
            image3: require("../components/images/10313/pic3.jpg"),
            image4: require("../components/images/10313/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10314,
        model: "Nokian Hakkapeliitta R3",
        size: "225/40/18",
        season: "kitka",
        year: "2019",
        loadSpdIdx: "92T",
        price: "80€",
        pinta: "6,8mm",
        images: {
            image1: require("../components/images/10314/pic1.jpg"),
            image2: require("../components/images/10314/pic2.jpg"),
            image3: require("../components/images/10314/pic3.jpg"),
            image4: require("../components/images/10314/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10315,
        model: "Michelin X-Ice North 3",
        size: "195/55/16",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "91T",
        price: "80€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10315/pic1.jpg"),
            image2: require("../components/images/10315/pic2.jpg"),
            image3: require("../components/images/10315/pic3.jpg"),
            image4: require("../components/images/10315/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10316,
        model: "Continental IceContact 2",
        size: "175/65/14",
        season: "nasta",
        year: "2017",
        loadSpdIdx: "86T",
        price: "80€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10316/pic1.jpg"),
            image2: require("../components/images/10316/pic2.jpg"),
            image3: require("../components/images/10316/pic3.jpg"),
            image4: require("../components/images/10316/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10317,
        model: "Nokian Hakkapeliitta 9",
        size: "205/55/16",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "94T",
        price: "60€",
        pinta: "7,2mm",
        images: {
            image1: require("../components/images/10317/pic1.jpg"),
            image2: require("../components/images/10317/pic2.jpg"),
            image3: require("../components/images/10317/pic3.jpg"),
            image4: require("../components/images/10317/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10318,
        model: "Continental IceContact 3",
        size: "205/55/16",
        season: "nasta",
        year: "2020",
        loadSpdIdx: "94T",
        price: "60€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10318/pic1.jpg"),
            image2: require("../components/images/10318/pic2.jpg"),
            image3: require("../components/images/10318/pic3.jpg"),
            image4: require("../components/images/10318/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10319,
        model: "Continental IceContact 2",
        size: "205/55/16",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "94T",
        price: "140€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10319/pic1.jpg"),
            image2: require("../components/images/10319/pic2.jpg"),
            image3: require("../components/images/10319/pic3.jpg"),
            image4: require("../components/images/10319/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10320,
        model: "Bridgestone Noranza 001",
        size: "205/55/16",
        season: "nasta",
        year: "2016",
        loadSpdIdx: "94T",
        price: "80€",
        pinta: "8,5mm",
        images: {
            image1: require("../components/images/10320/pic1.jpg"),
            image2: require("../components/images/10320/pic2.jpg"),
            image3: require("../components/images/10320/pic3.jpg"),
            image4: require("../components/images/10320/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10321,
        model: "Nokian Hakkapeliitta 8 SUV",
        size: "225/65/17",
        season: "nasta",
        year: "2014",
        loadSpdIdx: "106T",
        price: "150€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10321/pic1.jpg"),
            image2: require("../components/images/10321/pic2.jpg"),
            image3: require("../components/images/10321/pic3.jpg"),
            image4: require("../components/images/10321/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10322,
        model: "Michelin X-Ice XI3",
        size: "215/55/17",
        season: "kitka",
        year: "2018",
        loadSpdIdx: "98H",
        price: "170€",
        pinta: "6,7mm",
        images: {
            image1: require("../components/images/10322/pic1.jpg"),
            image2: require("../components/images/10322/pic2.jpg"),
            image3: require("../components/images/10322/pic3.jpg"),
            image4: require("../components/images/10322/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10323,
        model: "Nokian Hakkapeliitta R2 SUV",
        size: "275/40/20",
        season: "kitka",
        year: "2017",
        loadSpdIdx: "106R",
        price: "150€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10323/pic1.jpg"),
            image2: require("../components/images/10323/pic2.jpg"),
            image3: require("../components/images/10323/pic3.jpg"),
            image4: require("../components/images/10323/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10324,
        model: "Nokian Hakkapeliitta R3",
        size: "275/35/20",
        season: "kitka",
        year: "2018",
        loadSpdIdx: "102T",
        price: "150€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10324/pic1.jpg"),
            image2: require("../components/images/10324/pic2.jpg"),
            image3: require("../components/images/10324/pic3.jpg"),
            image4: require("../components/images/10324/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10325,
        model: "Continental ContiVikingContact 6",
        size: "225/55/18",
        season: "kitka",
        year: "2018",
        loadSpdIdx: "102T",
        price: "130€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10325/pic1.jpg"),
            image2: require("../components/images/10325/pic2.jpg"),
            image3: require("../components/images/10325/pic3.jpg"),
            image4: require("../components/images/10325/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10326,
        model: "Continental VikingContact 7",
        size: "225/55/17",
        season: "kitka",
        year: "2019",
        loadSpdIdx: "101T",
        price: "80€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10326/pic1.jpg"),
            image2: require("../components/images/10326/pic2.jpg"),
            image3: require("../components/images/10326/pic3.jpg"),
            image4: require("../components/images/10326/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10327,
        model: "Michelin X-Ice North 4",
        size: "195/65/15",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "95T",
        price: "130€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10327/pic1.jpg"),
            image2: require("../components/images/10327/pic2.jpg"),
            image3: require("../components/images/10327/pic3.jpg"),
            image4: require("../components/images/10327/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10328,
        model: "Michelin X-Ice North 4",
        size: "195/65/15",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "95T",
        price: "130€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10328/pic1.jpg"),
            image2: require("../components/images/10328/pic2.jpg"),
            image3: require("../components/images/10328/pic3.jpg"),
            image4: require("../components/images/10328/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },






    // {
    //     id: ,
    //     model: "",
    //     size: "//",
    //     season: "",
    //     year: "",
    //     loadSpdIdx: "",
    //     price: "€",
    //     pinta: "mm",
    //     images: {
    //         image1: require("../components/images/100/mic1.JPG"),
    //         image2: require("../components/images/100/mic2.JPG"),
    //         image3: require("../components/images/100/mic3.JPG"),
    //         image4: require("../components/images/100/mic4.JPG")
    //     },
    //     quantity: ,
    //     hidden: false
    // },

];

let models = new Set();
tiresAll.map(h => models.add(h.model));/////

let sizes = new Set();
tiresAll.map(h => sizes.add(h.size));

let seasons = new Set();
tiresAll.map(h => seasons.add(h.season));


let tires_visible = [];
tiresAll.map(h=> 
    h.hidden === false ?
    tires_visible.push(h)
        : null
    )

    tires_visible.sort((a, b) => a.id > b.id ? 1 : -1);


const db = { tiresDB: tires_visible, models: models, sizes: sizes, seasons: seasons};


export default db;